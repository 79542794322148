@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;

  &__inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @include media(mobile-l) {
      position: fixed;
      left: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.5);
      width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-black);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 25px 0 auto;

    @include media(tablet-wide) {
      margin: 0 auto;
    }

    @include media(mobile-l) {
      z-index: -1;
      flex-direction: column;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 24px;

      @include media(tablet-wide) {
        margin-right: 12px;
      }

      @include media(tablet) {
        margin-right: 8px;
      }

      @include media(mobile-l) {
        margin-right: 0;
      }
    }

    @include media(mobile-l) {
      margin-bottom: 20px;
    }
  }

  &__link {
    font-weight: var(--fw-500);
    font-size: 16px;
    line-height: 1.21;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: var(--color-white);

    &.true {
      color: var(--color-green-100);
    }

    @include media(tablet) {
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  .btn {
    padding: 7px 19px;
    font-size: 20px !important;
    line-height: 1.21;
    letter-spacing: 0.1px;

    &--simple {
      margin-right: 16px;
      min-width: unset;
    }

    @include media(tablet-wide) {
      padding: 8px 10px;
      font-size: 16px !important;
    }

    @include media(mobile-l) {
      margin: 0 auto;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &.formsapp-popup-button {
        margin: 0 auto;
      }
    }
  }

  .logo__img {
    max-width: 291px;

    @include media(tablet-wide) {
      max-width: 200px;
    }

    @include media(tablet) {
      max-width: 180px;
    }

    @include media(mobile-l) {
      max-width: 200px;
    }
  }
}
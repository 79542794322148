@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.list {
  width: 100%;
  max-width: 795px;

  ul {
    padding: 0 0 35px 36px;
  }

  li {
    list-style: disc;
    color: var(--color-white);
  }

  p,
  span {
    font-weight: var(--fw-300);
    font-size: 24px;
    line-height: 1.4;
    color: var(--color-white);
  }

  span {
    font-weight: var(--fw-600);
  }

  .hidden {
    display: none;
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.reviews {
  padding: 89px 0;
  background-color: var(--color-gray-200);

  h2 {
    margin-bottom: 32px;
  }

  &__content {
    margin-bottom: 64px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--color-gray-250);
    padding-bottom: 23px;

    h3 {
      font-weight: var(--fw-600);
      font-size: 24px;
    }
  }

  &__item {
    padding: 24px;
    background-color: var(--color-gray-150);

    p {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  &__avatar {
    margin-right: 16px;
    width: 100%;
    max-width: 48px;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__grid {
      grid-template-columns: 1fr;
    }

    &__image {
      margin-bottom: 12px;
      max-width: 500px;
    }

    &__content {
      margin: 0 0 20px;
    }
  }
}
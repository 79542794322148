@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.projects {
  padding: 87px 0 84px;

  h2 {
    margin: 0 auto;
    margin-bottom: 47px;
  }

  h3 {
    margin-bottom: 8px;
    letter-spacing: 0.5px;
    color: var(--color-orange-300);
  }

  p {
    font-size: 14px;
    line-height: 1.6;
  }

  .btn {
    margin: 0 auto;
    padding: 18px 32px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 46px;
    gap: 24px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: var(--radius-main);
    height: 100%;
    box-shadow: var(--shadow-primary);
    background-color: var(--color-orange-150);

    .btn {
      margin: 0;
      padding: 10px 7px;
      min-width: unset;
      background-color: transparent;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
  }

  @include media(tablet) {
    padding: rem(60) 0;
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      margin-bottom: 20px;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 16/7;
    }

    .container {
      flex-direction: column;
      align-items: center;
    }
  }

  @include media(mobile-s) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
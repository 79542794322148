@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding: 86px 0;
  line-height: 1.5;
  color: var(--color-white);

  &__content {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  .container {
    padding: 64px 101px;
    background-color: var(--color-gray-200);
  }

  h1 {
    position: relative;
    margin: 0 auto 32px;
    font-weight: var(--fw-400);
    font-size: clamp(36px, 3.2451rem + 1.0458vi, 70px);
    line-height: 1.21;
  }

  h2 {
    margin-bottom: 24px;
    font-family: var(--font-family-primary);
    font-weight: var(--fw-400);
    font-size: 38px;
    line-height: 1.1;
  }

  ul {
    margin-bottom: 30px;
    padding-left: 20px;
  }

  li {
    list-style: disc;
    font-weight: var(--fw-400);
    font-size: 20px;
    line-height: 1.2;
    color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 1.1px;
    color: var(--color-white);

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  a {
    text-decoration: underline;
    text-transform: none;
    color: var(--color-white);
  }

  @include media(tablet) {
    padding: 32px 0;

    .container {
      padding: 32px 10px;
    }
  }
}
/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  padding: 16px 32px;
  width: 100%;
  min-width: 100px;
  max-width: fit-content;
  font-weight: var(--fw-600);
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-black);
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s;

  &:hover,
  &:not(.slick-arrow):focus {
    color: var(--color-white);
    background-color: var(--color-green-200);
  }

  &.formsapp-popup-button {
    margin: 0;
    border: 1px solid transparent;
    border-radius: 0;
    font-family: var(--font-family-primary);
    font-size: 18px;
    color: var(--color-black);

    &:hover,
    &:focus {
      color: var(--color-white);
      background-color: var(--color-green-200);
    }
  }

  &--request {
    color: var(--color-black) !important;
    background-color: var(--color-white) !important;

    &:hover,
    &:focus {
      color: var(--color-white) !important;
      background-color: var(--color-green-200) !important;
    }
  }

  &--simple {
    border-color: var(--color-gray-50);

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  @include media(mobile-l) {
    padding: 10px 15px;
    font-size: 18px;
  }
}
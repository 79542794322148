@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.history {
  $root: &;

  padding: 86px 0;

  &__inner {
    padding: 86px 101px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 1196px;
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;

    &--journey {
      margin-bottom: 86px;

      #{$root}__item {
        &:last-child {
          align-items: center;
          justify-self: center;
          grid-column: 1 / span 3;
          width: 100%;
        }
      }
    }

    &--growth {
      min-height: 822px;

      #{$root}__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
      }
    }
  }

  h2 {
    margin-bottom: 86px;
  }

  &__content {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 62px;

    h2 {
      margin-bottom: 0;
      font-weight: var(--fw-400);
      font-size: 56px;
    }

    &--reverse {
      h2 {
        padding-right: 11px;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  p {
    width: 100%;
    max-width: 491px;
    font-size: 24px;
  }

  img {
    width: 100%;
  }

  .btn {
    padding: 15px 32px;
    letter-spacing: 0.4px;
  }

  @include media(tablet) {
    padding: 30px 0 0;

    h2 {
      margin: 0 auto;

      br {
        display: none;
      }
    }

    &__inner {
      min-height: 526px;
      padding: 32px 0;
    }

    &__content {
      h2 {
        font-size: clamp(20px, 2rem + 1vi, 70px);
        margin-right: 20px;
      }

      p {
        font-size: 20px;
      }
    }
  }

  @include media(mobile-l) {
    &__inner {
      min-height: 500px;

      &--journey {
        margin-bottom: 32px;

        #{$root}__item  {
          &:last-child {
            grid-column: auto;
          }
        }
      }

      &--growth {  
        #{$root}__grid {
          grid-template-columns: 1fr;
        }
      }
    }

    p {
      margin-bottom: 20px;
    }

    &__content {
      margin-bottom: 32px;
      flex-direction: column;

      &--reverse {
        flex-direction: column-reverse;
      }

      h2 {
        margin: 0 0 16px 0;
      }
    }

    &__grid {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }

      img {
        width: 100%;
        aspect-ratio: 16/7;
      }
    }
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.team {
  h2 {
    margin-bottom: 32px;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 89px 0;
    min-height: 901px;
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    margin-bottom: 64px;

    p {
      width: 100%;
      max-width: 592px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--color-gray-250);
    padding-bottom: 23px;

    h3 {
      font-weight: var(--fw-600);
      font-size: 24px;
      line-height: 1.21;
    }
  }

  &__item {
    padding: 24px;
    background-color: var(--color-gray-150);

    p {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  &__avatar {
    margin-right: 16px;
    width: 100%;
    max-width: 86px;
  }

  &__name {
    p {
      font-size: 20px;
    }
  }

  @include media(tablet) {
    padding: 30px 0;

    &__inner {
      padding: 0;
    }

    &__image {
      margin-bottom: 12px;
      max-width: 500px;
    }

    &__content {
      margin: 0 0 20px;
    }

    &__grid {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}
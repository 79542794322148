@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.about {
  padding: 142px 0 86px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 87px;

    p {
      width: 100%;
      max-width: 512px;
      line-height: 1.6;
      text-transform: capitalize;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      margin-bottom: 48px;
      width: 100%;
      max-width: 794px;
      font-weight: var(--fw-300);
      font-size: 32px;
      line-height: 1.4;
      text-transform: capitalize;
      color: var(--color-white);
    }
  }

  &__images {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 86px;
    max-height: 358px;
  }

  img {
    &:not(:last-child) {
      margin-right: 26px;
    }
  }

  .btn {
    letter-spacing: 0.5px;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__content {
      margin-right: 20px;
    }

    h2 {
      font-size: clamp(20px, 2rem + 1vi, 40px);
    }
  }

  @include media(mobile-l) {
    flex-direction: column;

    &__header {
      flex-direction: column;
      margin-bottom: 32px;
    }

    &__content {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: unset;
    }

    &__image {
      width: 100%;
    }

    &__images {
      margin-bottom: 32px;
    }

    h2 {
      br {
        display: none;
      }
    }

    p,
    .container {
      max-width: unset;
    }

    p {
      margin-bottom: 20px;
    }
  }
}
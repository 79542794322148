@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.service {
  $root: &;

  padding: 88px 0 0;

  h2 {
    margin-bottom: 85px;
  }

  h3 {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 1.21;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 2px solid var(--color-gray-200);
    padding: 110px 27px 28px;
    width: 100%;
    height: 100%;
    max-width: 491px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      content: "";
      position: absolute;
      left: 27px;
      top: 28px;
      border-radius: 16px;
      width: 50px;
      height: 50px;
      background-image: url("../images/icons/capturing.svg");
      background-position: center;
      background-size: 50px;
      background-repeat: no-repeat;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
    }

    &--wedding {
      margin: 0 64px 64px 0;
      background-image: url("../images/services/wedding-photography.jpeg");

      &::before {
        background-image: url("../images/icons/wedding.svg");
        background-size: 50px;
      }
    }

    &--corporate {
      background-image: url("../images/services/corporate-events.jpeg");

      &::before {
        background-image: url("../images/icons/corporate.svg");
        background-size: 50px;
      }
    }

    &--parties {
      background-image: url("../images/services/parties.jpeg");

      &::before {
        background-image: url("../images/icons/parties.svg");
        background-size: 50px;
      }
    }
  }

  &--portfolio {
    padding-bottom: 86px;
    
    h2 {
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 86px;
      width: 100%;
      max-width: 592px;
    }

    #{$root}__item {
      &::before {
        top: 27px
      }
      
      p {
        margin-bottom: 0;
      }
    }
  }

  @include media(tablet) {
    padding: 30px 0;

    &--portfolio {
      p {
        margin-bottom: 32px;
      }
    }

    h2  {
      margin-bottom: 32px;
    }

    h3 {
      font-size: clamp(16px, 2rem + 1vi, 24px);
    }

    &__item {
      max-width: unset;
      margin-bottom: 16px;

      &--wedding {
        margin: 0 0 16px 0;
      }
    }
  }

  @include media(mobile-l) {
    &__item {
      padding-top: 100px;

      &:last-child {
        grid-column: auto;
      }
    }
  }
}
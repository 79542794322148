@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.services {
  $root: &;

  &--home {
    padding: 86px 0;
    background-color: var(--color-black);

    h2 {
      margin-bottom: 53px;
      text-align: end;
      width: 100%;
    }

    p {
      width: 100%;
      max-width: 512px;
      text-transform: capitalize;
    }

    #{$root}__content {
      display: flex;
      align-items: end;
      justify-content: space-between;
      margin-bottom: 88px;

      .btn {
        letter-spacing: 0.4px;
        padding: 15px 32px;
      }
    }
 
    @include media(tablet) {
      padding: 30px 0;

      h2 {
        margin-bottom: 20px;
      }

      #{$root}__content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .btn {
          margin-top: auto;
        }
      }
    }

    @include media(mobile-l) {
      img {
        width: 100%;
        aspect-ratio: 16/7;
      }

      #{$root}__content {
        flex-direction: column;
      }
    }
  }
}
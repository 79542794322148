@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: clamp(12px, rem(20), 22px) 0;
  width: 100%;
  background-color: var(--color-black);
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-100%);
  }

  &.hide.scroll {
    transform: translateY(0);
  }

  @include media(tablet-wide) {
    .logo__img {
      max-width: 180px;
    }
  }

  @include media(mobile-sm) {
    .logo {
      max-width: 80%;
    }
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.pricing {
  padding: 84px 0 0;

  h2 {
    margin-bottom: 63px;
  }

  h3 {
    margin-bottom: 24px;
    border-bottom: 2px solid var(--color-green-100);
    width: 100%;
    height: 121px;
    font-size: 40px;
    line-height: 1.21;
    text-transform: uppercase;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 89px 0;
    min-height: 901px;
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 65px;
    gap: 16px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 98px 24px 24px;
    width: 100%;
    height: 100%;
    max-width: 491px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--color-green-200);

    &::before {
      content: "";
      position: absolute;
      left: 24px;
      top: 24px;
      border-radius: 16px;
      width: 50px;
      height: 50px;
      background-image: url("../images/icons/capturing.svg");
      background-position: center;
      background-size: 50px;
      background-repeat: no-repeat;
    }

    p {
      margin-top: auto;
      line-height: 1.5;

      span {
        color: var(--color-green-100);
      }
    }

    &--wedding {
      &::before {
        background-image: url("../images/icons/wedding.svg");
        background-size: 50px;
      }
    }

    &--corporate {
      &::before {
        background-image: url("../images/icons/corporate.svg");
        background-size: 50px;
      }
    }

    &--parties {
      &::before {
        background-image: url("../images/icons/parties.svg");
        background-size: 50px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      margin-bottom: 47px;
      width: 100%;
      max-width: 794px;
      font-weight: var(--fw-300);
      font-size: 32px;
      line-height: 1.4;
      text-transform: capitalize;
      color: var(--color-white);
    }
  }

  .btn {
    background-color: transparent;
  }

  @include media(tablet) {
    padding: 30px 0;

    &__inner {
      padding: 32px 0;
    }

    &__grid {
      grid-template-columns: 1fr;
      margin-bottom: 32px;
      gap: 16px;
    }

    &__item {
      max-width: unset;
    }

    &__content {
      p {
        margin-bottom: 32px;
        font-size: 24px;
      }
    }

    h2 {
      margin-bottom: 32px;
    }

    h3 {
      padding-bottom: 16px;
      height: auto;
    }
  }

  @include media(mobile-l) {
    &__item {
      padding-top: 100px;

      &:last-child {
        grid-column: auto;
      }
    }
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.weddings {
  padding: 86px 0;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 86px 101px;
    min-height: 1196px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  h2 {
    margin-bottom: 32px;
    font-weight: var(--fw-400);
    font-size: 56px;
  }

  &__content {
    z-index: 1;
    margin-bottom: 63px;

    &--reverse {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: end;
      margin-bottom: 64px;

      p {
        text-align: end;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  &__item {
    &:last-child {
      align-items: center;
      justify-self: center;
      grid-column: 1 / span 3;
      width: 100%;
    }
  }

  &__hidden {
    display: none;
  }

  p {
    width: 100%;
    max-width: 592px;
    font-size: 20px;
    line-height: 1.6;
  }

  img {
    width: 100%;
  }

  .btn {
    padding: 15px 32px;
    letter-spacing: 0.4px;
  }

  &--corporate {
    padding: 0;

    .weddings__inner {
      min-height: 886px;
    }
  }

  &--parties {
    padding-bottom: 174px;
  }

  @include media(tablet) {
    padding: 0;

    &__inner {
      padding: 0;
      min-height: 526px;
    }

    &__content {
      margin-bottom: 32px;
      padding: 20px;
    }

    h2 {
      margin-bottom: 16px;
    }
  }

  @include media(mobile-l) {
    &__inner {
      min-height: 500px;
    }

    &__grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    &__item {
      &:last-child {
        grid-column: auto;

        img {
          height: 100%;
        }
      }
    }

    &--corporate {
      .weddings__item {
        &:nth-child(3) {
          grid-column: 1 / span 3;

          img {
            aspect-ratio: 16/7;
          }
        }
      }
    }
  }

  @include media(mobile-s) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &--corporate {
      .weddings__item {
        &:nth-child(3) {
          grid-column: auto;
        }
      }
    }

    img {
      aspect-ratio: 16/7;
    }
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.articles {
  padding: 86px 0 109px;

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 64px 0;
    gap: 16px;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;

    p {
      font-weight: var(--fw-500);
      font-size: 18px;
      line-height: 1.21;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  h1 {
    margin-bottom: 32px;
    font-size: 70px;
  }

  h3 {
    display: flex;
    margin-bottom: 16px;
    line-height: 1.21;

    span {
      margin-right: 12px;
      font-weight: var(--fw-600);
      font-size: 24px;
      line-height: 1.21;
      color: var(--color-gray-100);
    }
  }

  &__image {
    margin-bottom: 23px;
    width: 100%;
  }

  .btn {
    margin: 0 auto;
    padding: 10px 60px;
    max-width: fit-content;
  }

  @include media(tablet) {
    padding: 40px 0;

    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media(mobile-l) {
    h2 {
      margin-bottom: 24px;
    }

    &__grid {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }

    img {
      aspect-ratio: 16/7;
    }
  }

  @include media(mobile-m) {
    .btn {
      max-width: none;
    }
  }
}
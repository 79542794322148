@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.approach {
  padding: 84px 0 173px;

  h2 {
    margin-bottom: 63px;
  }

  h3 {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 1.21;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 2px solid var(--color-gray-200);
    padding: 110px 27px 27px;
    width: 100%;
    height: 100%;
    max-width: 491px;

    &::before {
      content: "";
      position: absolute;
      left: 27px;
      top: 28px;
      border-radius: 16px;
      width: 50px;
      height: 50px;
      background-image: url("../images/icons/capturing.svg");
      background-position: center;
      background-size: 50px;
      background-repeat: no-repeat;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
    }

    &--capturing {
      &::before {
        background-image: url("../images/icons/capturing.svg");
        background-size: 50px;
      }
    }

    &--philosophy {
      &::before {
        background-image: url("../images/icons/philosophy.svg");
        background-size: 50px;
      }
    }
  }

  @include media(tablet) {
    padding: 30px 0;

    &__list {
      flex-direction: column;
    }

    &__item {
      max-width: unset;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    h2 {
      margin-bottom: 32px;
    }

    h3 {
      font-size: clamp(16px, 2rem + 1vi, 24px);
    }
  }

  @include media(mobile-l) {
    &__item {
      padding-top: 100px;

      &:last-child {
        grid-column: auto;
      }
    }
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.additional {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 34px;
    gap: 16px;
  }

  h2 {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 35px;
    width: 100%;
    max-width: 795px;
    font-weight: var(--fw-300);
    font-size: 24px;
    line-height: 1.4;
    color: var(--color-white);

    span {
      font-weight: var(--fw-600);
      font-size: 24px;
      line-height: 1.4;
      color: var(--color-white);
    }
  }

  .btn {
    padding: 18px 32px;
  }

  &.image--hidden {
    img {
      display: none;
    }
  }

  &--two {
    .additional__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--one {
    img {
      margin-bottom: 32px;
    }
  }

  @include media(tablet) {
    padding: 30px 0;

    &__content {
      margin-right: 20px;
    }

    h2 {
      font-size: clamp(20px, 2rem + 1vi, 40px);
    }
  }

  @include media(mobile-l) {
    &__content {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: unset;
    }

    &__image {
      width: 100%;
    }

    &__grid {
      grid-template-columns: 1fr;
    }

    &--two {
      .additional__grid {
        grid-template-columns: 1fr;
      }
    }

    p,
    .container {
      max-width: unset;
    }

    p {
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      aspect-ratio: 16/7;
    }
  }
}